.customerFormHead{
    text-align: center;
    justify-content: center;
    place-items: center;

    font-size: 26px;
}
.cardDesign{
    border:1px solid #a1a0a0;
    transform: 'scale(0.8)' ;
    padding:5px;
  }
  .badgeIcon{
    top:3px  !important;
  }
  .customerTitle{
    font-size: 22px;
  }
  .customerSubHead{
    font-size: 16px;
    font-weight: 500;

  }
  .billingAddress{
    font-size: 15px;
    font-weight: 500;
  }
  .shippingAddress{
    font-size: 14px;
    font-weight: 500;
  }
  .customDatePicker{
    max-height:40px;
  }
  a {

    position: relative;
    text-decoration: none;
    color:#126fbb;
    font-weight: 500;

}


#file {
    position: absolute;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    cursor: pointer;
    right: 0;
}