.invoiceRows {
    font-size: 14px !important;
}

.create {
    float: right;
    margin-bottom: 20px !important;
    font-size: 15px;
    font-weight: 500;
}

.errorOutlineIcon {
    color: rgb(230, 170, 7);

}

.tabs {
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    /* font-weight: 500 !important; */
}

.batchActions {
    border-radius: 10px !important;
    border: 1px solid black;
}

.tablerow {
    border: 1px solid #ccc;
    padding: 6px !important;
    border-top:none !important;
    font-size: 15px !important;
    /* border-left:none !important; */
}

.invoiceRow {
    border: 1px dashed #ccc;
    padding: 10px !important;
    font-size: 14px !important;
}
.turnLeft{

          transform: "rotate(60deg)"
    
}