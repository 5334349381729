.ganttVerticalContainer {
  overflow: scroll;
  overflow-y: hidden;
  font-size: 0;
  margin: 0;
  padding: 0;
  /* height:350px */
  /* height: 700px !important; */
  height: 730px !important;
}

.horizontalContainer {
  margin: 0;
  padding: 0;
  /* overflow-x: scroll; */
  overflow-y: hidden ;
  width:730px;
  /* height: 360px !important; */
  /* height: 320px !important; */
  height: 730px !important;
}

.wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  /* position: relative; */
  border: 1px solid #c9c9cc;
  overflow-y:scroll;
}
.reactSplitPane{
  display: flex !important;
}