/* Add your custom styling here */
.otp-input-container {
    display: flex;
    justify-content: space-between;
    width: 200px; /* Adjust the width based on your design */
  }
  
  .otp-input {
    width: 2rem;
    height: 2rem;
    text-align: center;
    font-size: 1rem;
    margin: 0 0.5rem;
  }
  .otpImg{
    display: flex !important;
    justify-content: center !important;
  }
  .otpTitle{
    display: flex !important;
    justify-content: center !important;
    font-size: 22px !important;
    font-weight:600;

  }
  .otpSubTitle{
    display: flex !important;
    justify-content: center !important;
    font-size: 15px !important;
    font-weight:500;
    color:#6b6969;
  }
  .otpcodeTitle{
    display: flex !important;
    justify-content: center !important;
  }
  .otpVerifyBtn{
    justify-content: center;
    display: flex;
  }
  /* YourComponent.css */

.progress-bar {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: #E3F2FD;
  padding: 8px;
  display: flex;
  align-items: center;
}

.progress-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Full width initially */
  height: 3px; /* Height of the progress bar */
  background: linear-gradient(to right, blue, green); /* Blue and green colors only */
  animation: progressAnimation 2s linear forwards; /* Animation duration and timing function */
}

@keyframes progressAnimation {
  0% {
    width: 0; /* Start with 0 width */
  }
  100% {
    width: 100%; /* End with full width */
  }
}
