.taskName{
    font-size: 16px;
    font-weight: 500 !important;
    color: #3c5bfa;
}
.taskTime{
    font-size: 13px  !important;
}
.taskTimerIcon{
    font-size: 16px !important;

}
.taskNo{
    background-color: #3c5bfa;
    color: white;
    padding: 5px;
    padding-left: 19px;
    font-size:15px;
}