.timeclockcard{
    background-color: #056c44!important;
    color: white !important;
    border-radius: 15px !important;
}

.taskhead{
    font-size: 16px !important;
}

.gpstext {
    font-size: 10px;
}

.weatherimage {
    height: 40px;
}

.progressbar {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 35px;
}

.timeSeprator{
    font-family: monospace;
}

.timeProgress {
    display:inline-block;
    font-family: monospace;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(6) infinite;
    font-size: 0.75rem;  

    
  }
  .timeDelay{
       
    text-overflow: ellipsis;
    overflow: hidden;        
    word-wrap: break-word; 
    justify-content: center;
    align-items: center;
    
    
  }