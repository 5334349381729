.VendorTitle {
    font-weight: 600;
    font-size: 18px;
}

.VendorHead {
    font-size: 26px;
    text-align: center;
}

.vendorcard {
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.subtitle {
    font-weight: 600;
}

a {
    position: relative;
    text-decoration: none;
    font-size: 20px;
}

#file {
    position: absolute;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    cursor: pointer;
    right: 0;
}