// buttonStyles.scss

.custom-button {
  position: absolute;
  bottom: 45%;
  min-width: 160px;
}

@media (min-width: 576px) {
  .custom-button {
    left: 65%; 
  }
}

@media (min-width: 768px) {
  .custom-button {
    left: 68%; 
  }
}

@media (min-width: 992px) {
  .custom-button {
    left: 70%; 
  }
}

@media (min-width: 1200px) {
  .custom-button {
    left: 72%;
  }
}

@media (min-width: 1400px) {
  .custom-button {
    left: 72.5%;
  }
}
@media (min-width: 1900px) {
  .custom-button {
    left: 76%;
  }
}


.loginbgleft {
  background-image: url("../assets/login/left.jpeg");
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;              

}


.loginbgright {
  background-image: url("../assets/login/right.svg");
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;              

}

