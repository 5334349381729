.salescardheight{    
    height: 100%;
    display:flex;
    direction: column;
    align-items: stretch;
    width: 100%;
}

.salescardimage{
    width: -webkit-fill-available;
}

.imagemargin{
    margin: 20px 20px 0px 20px;
}

.chartimagemargin{
    margin: 90px 20px 0px 20px;
}

.cardFont{
 font-family: Inter, sans-serif, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;

}