.checkoutConfirm{
    background-color: #1293bb !important;
    color: #fff !important;
    justify-content: center;
    display: flex;
    font-size: 12px !important;
    max-width: fit-content;
    padding:1px;
    // place-items: center;
    border-radius: 7px;
    float:inline-start;
  }
  .otpVerify{
    float:inline-end;

    
  }
  .activeProject{
    background-color: #d4ffd7 !important;
    color: #1ea33f !important;
    justify-content: center;
    display: flex;
    text-align: center;
    border-radius: 7px;
  }
  .statusCheckout{
    background-color: #fff1e3 !important;
    color: #ff9f43 !important;
    justify-content: center;
    display: flex;
    text-align: center;
  }
  // .projectStatusDate{
  //   font-size: 12px !important;
  //   border: 1px dashed #ccc !important;
  
  // }
  .file-btn {
    // width: 330px;
    // height: 150px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    color: #5d4dcc;
    background-color: #fff;
    border: 1.5px dashed #5d4dcc;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .file-btn:hover {
    color: #5d4dcc;
    background-color: #fff;
  }
  
  .file-btn span {
    width: 50px;
    height: 50px;
    font-size: 30px;
    color: #5d4dcc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: #f1efff;
  }
  
  .file-card {
    // width: 300px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #000;
    background-color: #fff;
    border: 1px solid rgba(117, 96, 255, 0.281);
    border-radius: 6px;
    padding: 5px 5px;
  }
  
  .file-info {
    flex: 1;
    display: flex;
    align-items: center;
    // gap: 15px;
  }
  
  .file-info h6 {
    flex: 1;
 font-size: 12px;
    font-weight: 400;
  }
  
  .progress-bg {
    width: 100%;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.076);
    border-radius: 8px;
    margin-top: 8px;
    min-width:100px !important;
  }
  
  .progress {
    width: 0%;
    height: 5px;
    background-color: #5d4dcc;
    border-radius: 8px;
    transition: width 0.5s ease;
  }
  
  .icon {
    font-size: 30px;
    color: #7460ff;
  }
  
  .close-icon {
    font-size: 13px;
    cursor: pointer;
  }
  
  .file-info button,
  .check-circle {
    // width: 36px;
    // height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #463a99;
    // background-color: #f1efff;
    border: none;
    border-radius: 30px;
  }
  
  .upload-btn {
    // width: 330px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    // background-color: #7460ff;
    border: none;
    border-radius: 8px;
    padding: 10px;
    margin-top: 15px;
    cursor: pointer;
  }
  .btnForClose{
    padding: 0 !important;
  }
  .fileDetails{
    line-height: 0.0 !important;
  }
  .iconsForAction{
    justify-content: center;
    place-items: center;
    
  }