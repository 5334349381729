.calendarBottomText {
  text-anchor: middle;
  fill: #333;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  border:1px solid #333;
}

.calendarTopTick {
  stroke: #e6e4e4;
  /* border:1px solid #333; */
}

.calendarTopText {
  text-anchor: middle;
  fill: #555;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  border-bottom:#ffffff;
  /* border:1px solid #333; */
}

.calendarHeader {
  fill: #eeeeee;
  stroke: #c9c9cc;;
  stroke-width: 1.4;
  /* border-bottom:1px solid #5f0b0b !important; */
  color:white;
  
}
/* .task-list-table_taskListTableRow__nDzTr{
  border-bottom: 1px solid #991515;;
} */