.invoiceDateNo{
    padding-left: 30px;
   font-size: 16px !important;
}
.costInformation{
    background-color: #6c757d!important;
    color:white;
    font-size: 15px !important;
    font-weight:500 !important;
    border-radius: 6px !important;
}
.totalCost{
    background-color: #28a745!important;
    color:white;
    font-size: 15px !important;
    font-weight:500 !important;
    border-radius: 6px !important;
}
.pendingPayment{
    background-color: #6c757d!important;
    color:white;
    font-size: 15px !important;
    font-weight:500 !important;
    border-radius: 6px !important;
}
.paymentDetails{
    font-size:14px;
}
.attachMoneyIcon{
    font-size:23px !important;
    font-weight: 600 !important;

}
.warningIcons{
    box-sizing: border-box;
    color:#ffc107!important;
    font-weight: 900 !important;
    border-radius: 6px !important;
    font-size: 22px;
}
.datePaid{
    color: #28a745!important;
}
.creditIcon{
  font-size:23px !important;
}
.confirmPayment{
    display: flex;

}