.myDiv {
    position: relative;  /*Parent MUST be relative*/
    z-index: 9;
    background: white;
    
    /*Set width/height of the div in 'parent'*/
    width: 100%;
    min-height: 100px;
  }
  .myDiv:before {
    content: "";
    position: absolute; /*set 'child' to be absolute*/
    z-index: -1;  /*Make this lower so text appears in front*/
    
    
    
    /*You can choose to align it left, right, top or bottom here*/
    top: 0;
    right: 0;
    bottom: 40%;
    left: 0;
    background: #3c5bfa;
  }
  .unitStyle{
    padding-bottom:15px;
  }