.createbidbtn {
   
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #7367f029 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
   margin-top: 7px !important;
  color: #7367f0 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #7367f03d !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}
  
.prevnxtbtn{
  border-radius: 8px !important;
  margin-bottom: 0px !important;
  margin-top: -2px !important;
  font-weight: 500 !important;
}
.textfield{
  // padding: 6px !important;
}