.cartcard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  margin-top: 12px;
  padding: 25px;
}

.cartheader {
  font-size: 18px !important;
  font-weight: bold !important;
}

.highlight {
  font-size: 15px !important;
  color: #3c5bfa;
  font-weight: bold !important;
  // margin-top: 5px !important;
}
.highlightText {
  font-size: 2rem;
  color: #808080;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  font-weight: 450 !important;
}

.textstyle {
  display: flex;
  place-items: center;
}

.tradetitle {
  // font-size: 24px !important;
  // font-weight: bold !important;
}

.oldvalue {
  color: #db5252f3;
  text-decoration: line-through !important;
  // margin-top: -48px !important;
  // text-align: center !important;
}
