.otpsalesBooking{
    height:60px;
}

.selectedDate{
    font-size: 20px;
    font-weight: 500;
    color:rgb(8, 55, 109)
}
.salesBookingDetails{

    place-items: center;
    /* display: flex; */
    justify-content: center;
    display: grid;
}