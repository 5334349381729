.timesheetEdit {
    font-size: 11px;
    text-transform: uppercase;
    color: #f1f1f1;
    background-color: #666;
    padding: 2px 12px 3px 12px;
    border-radius: 3px;
    margin-right: 5px;
}
.timesheetEditLabel {
    font-weight: 500;
    font-size: 12px;
    color: #666666;
    vertical-align: inherit;
    text-transform: uppercase;
    height: 30px;
    padding-right: 19px;
}

 .timesheetEditTotal {
    border: 1px solid #DADADA;

    font-weight: 400;
    font-size: 14px;
}
.sendApprove{
    height:35px !important;
}
.statusAwaitingApproval{
    background-color: #f1dfda !important;
    color: #ff6f43 !important;
    justify-content: center;
    display: flex;
    text-align: center;
    border-radius: 5px;
  }
  .timeSheetStart{
    color:rgb(32, 161, 32);
  }
  .timeSheetEnd{
    color:rgb(165, 32, 9);
  }
  .collapsibleTable{
    color:white !important;
  }
  .tableTimeSheet{
    min-width: 180px;
  }