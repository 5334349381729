.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.paymentHead {
  font-size: 18px !important;
  font-weight: 400;
}

.paymentTitle {
  padding: 6px 12px !important;
}

.orderDetails {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.closeIcon {
  float: inline-end !important;
}

.personalDetails {
  font-size: 16px !important;
  color: rgb(5, 28, 148);
}

.lineItemCost {
  display: flex;
  float: right;
}

.centerDetails {
  place-items: center;
  display: flex !important;
}

.failed{
  background-color:#df3333ed !important;
  border-radius:none !important;
  place-items: center;
  display: flex;
  justify-content: center;
}

.success{
  background-color:#4aa02c !important;
  border-radius:none !important;
  place-items: center;
  display: flex;
  justify-content: center;
}

.cash {
  color: white;
  font-size: 15px !important;
}

.goBack {
  justify-content: flex-end !important;
  float: inline-end !important;
}

.makePaymentHead {
  background-color: #32c75f;
}

.paidIcon {
  font-size: 57px !important;
  place-items: center;
  display: flex !important;
}

.customerName {
  border: 1px solid #0baa3b;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  // background-color: #18ad45;
  font-weight: 500;
}

.tableDetails {
  font-size: 13px !important;
}

.tableTop {
  border-top: 1px solid #dddcdc !important;
}

.itemPay {
  border-bottom: 1px solid #ccc !important;
 
}

.listDetails {
  border: 1px solid #ccc;
  padding-top:0px !important;
  padding-bottom:0px !important;
}

.filePay {
  justify-content: center;
  place-items: center;
  text-align: center;
}

#form-file-upload {
  height: 12rem;
  // width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  line-height: 0.2;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #b9b9b9;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  // padding: 0.25rem;
  font-size: 18px;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.rightButtons {
  float: right !important;
}

.paymentBox {
  overflow-y: scroll;
}

.itemQuantity {
  font-size: 14px !important;
}

.dollar {
  color: rgb(194, 8, 8);
}

.dollarAmount {
  font-weight: 500;

}

.itemDetails {
  border: 1px solid #ececec
}

.complete {
  background-color: white !important;
  color: #3c5bfa !important;
  font-size: 14px !important;
  border-radius: 10px;
}

.makePaymentDailog {
  border-radius: 10px !important;
}

.currentDate {
  margin-right: 9px !important;
}
.contentDetails {
  max-height: 70vh;
  overflow-y: auto;
}
.totalamtinvc{
  color:white;
  font-size:14px;
}
.totalcost{
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 4px;
  // background-color: #18ad45;
}
.highlighted {
  background-color: yellow; /* Replace with your desired highlight style */
}
.itemPay-selected{
  background-color: #2c2929;
}
.completePayment{
     background-color: #18ad45 !important;
     color:white !important;
     font-size: 13px !important;
}

.otherPayment{
  //background-color: #bf54d5 !important;
  color:white !important;
  font-size: 13px !important;
}

.retry{
  background-color: #d55454 !important;
  color:white !important;
  font-size: 13px !important;
}

.itemPay {
  &:hover {
    background-color: inherit; // Hover color
  }

  &.selected {
    background-color: #3c5bfa !important; // Selected color
    color: white !important;

    .MuiListItemIcon-root,
    .MuiListItemText-secondary {
      color: white !important; // Set icon and secondary text color to white
    }
  }
}