.createBidCourousel{
    width: -webkit-fill-available;
   max-height:200px !important;
   object-fit:cover;
   border-radius: 6px !important;
  
}
.createBidContent{
    display:flex;
    justify-content:center ;
    font-size: 20px !important;
}
.imgContent{
    display:flex;
}
.createadvButton{
 color:  #3c5bfa !important;
background-color: white !important;
     box-shadow: 0 0.125rem 0.25rem rgba(165,163,174,.3);
    cursor: pointer;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    transition: all .135s ease-in-out;
    transform: scale(1.001);
    border-radius: 8px !important;
    height: 30px;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    margin-top: 7px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    
}
