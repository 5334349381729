.rightsidebar{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 300px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.css-140axzu-MuiDrawer-docked {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    // width: 300px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.css-8p9c84 {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    // margin-right: 304px;
}

.bottomabar{
    position: relative;
    z-index: 1;
}

.createBidBtn {
    // background-color: white !important;
    // color: transparent !important;
    // position: relative;
    background: -webkit-linear-gradient(#7f00ffd4, #3c5bfa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
//    margin-top: 6px !important;
    height: 30px;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    margin-top: 7px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    // padding: 5px !important;
   
}


  
 .createBidBtn:after {
    content: '';
    position: absolute;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    margin-top: 7px !important;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
   
    
    
    z-index: -1;
  }
//   .createBidBtn:hover {
//     background:  #f8f9fc98;

//  
.bugReportBtn{
    background: -webkit-linear-gradient(#7f00ffd4, #3c5bfa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
//    margin-top: 6px !important;
    height: 40px;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    margin-top: 7px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color:#7f00ffd4;
}