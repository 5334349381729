.formSubTitle {
 
  color: #717162  !important;
}

.formSubTitle:hover {
  color:'blue' !important;
  background:'none' !important;
}
.createBidFormCard {
  padding: 25px;
  border-radius: 15px !important;
  border: 0;
  // box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
}
.stepperText{
  color: white;
}
.stepNumber{
  display:'flex' !important;
  text-align: center;
}
.selectionCard {
  padding: 50px;
  border-radius: 15px !important;
  //  height: 400px;
  border: 0;
  // box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
  overflow-y: auto !important;
}
.options{
 padding-left:0px !important;
}
.selectionCard-title {
  padding: 0 !important;
}

.radiobutton {
  display: "inline-block";
  position: "relative";
  padding-top: 43%;
  margin-top: -43%;
  padding-right: 36%;
  padding-left: 36%;
  margin-right: -36%;
  margin-left: -36%;
  z-index: 1;
}

.step {
  padding-left: 20px;
  min-height: 60px;
  padding-bottom: 20px;
}

.active-step {
  border-radius: 10px;
}

.step-icon {
  border: "2px solid white";
  border-radius: 12px;
  background-color: white;
}

.ative-step-icon {
  border-radius: 12px;
  color: white;
  display: flex !important;
  justify-content: flex-start !important;
}

.selectionCard-option {
  width: 100%;
  background-color: "#fff" !important;
}

.selectionCard-option:hover {
  //   border: 2px solid blue;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

// .active-selectionCard-option {
//   inset: -1;
//   border: 2px solid #b6186a;
// }
.optionCard {
  border: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06) !important;
  }

.optionCard:hover {
//   border: 2px solid blue;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.unCheckedRadioButton {
  color: grey;
}

.stepper {
  border-right: 2px solid lightgrey;
  display: "inline-flex";
}

.actionButton {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  bottom: 0;
}

.buttonSpace {
  flex: 1 1 auto;
}

.recommendationCard {
  min-height: 312px;
}

.warrantyDuration{
  padding-top: 10px !important;
  font-size: 10px !important;
  text-align: center;
}

.estimate {
  color: #f6f5f3;
  font-size: 34px !important;
  font-weight: bold !important;
  padding-bottom: 8px !important;

}
.estimateACardContent {
  border: 0;
  box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
  box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
  
  padding: 15px;
}
.selected-estimate-card {
  //   border: 2px solid blue;
  background-color: #04aa6d !important;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  .estimateACardContent:hover {
    //   border: 2px solid blue;
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
    }

.estimateBCardContent {
  border: 0;
  box-shadow: rgba(179, 178, 183, 0.2) 0px 4px 18px 0px !important;
  height: 100%;
  padding: 15px;
}
.estimateBCardContent:hover {
//   border: 2px solid blue;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.estimateCCardContent {
  border: 0;
  box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
  height: 100%;
  padding: 15px;
}
.estimateCCardContent:hover {
//   border: 2px solid blue;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.estimateSubContent {
  color: white;
  font-size: 15px !important;
  margin-left: 2px !important;
}
.estimateSelect {
  padding-top: 6px !important;
  
  // padding-bottom: 10px !important;
}
.estimateASelectButton {
  border-radius: 6px !important;
  background-color: white !important;
  // color:black !important;
  padding-left: 16px !important;
  padding-right: 18px !important;
}
.estimateunSelectButton {
  border-radius: 6px !important;
  background-color: white !important;
  // color:black !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.estimateBSelectButton {
  border-radius: 6px !important;
  background-color: white !important;
  // color:black !important;
  padding-left: 16px !important;
  padding-right: 18px !important;
}
.estimateCSelectButton {
  border-radius: 6px !important;
  background-color: white !important;
  // color:black !important;
  padding-left: 16px !important;
  padding-right: 18px !important;
}

.recommendationButton{
  border-radius: 30px !important;
  // color:black !important;
  // padding-left: 30px !important;
  // padding-right: 30px !important;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimateCardContent:hover {
  border-color: blue !important;
}

.shoppingCartCard {
  padding-top: 10px !important;
  padding-left: 10px !important;
  // padding-right:10px !important;
  border-radius: 15px !important;
  border: 0;
  // box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
}

.offerCard {
  border-radius: 10px !important;
  background-color: #e6ffe6 !important;
  border-color: #e6ffe6 !important;
}

.offerCardContent {
  color: #198754 !important;
  padding: 10px !important;
}
.offerCardSubContent {
  font-size: 12px !important;
}

.shoppingCardHeader {
  font-size: 20px !important;
  padding: 10px !important;
}

.shoppingCardPrice {
  font-weight: 1000 !important;
  font-size:30px !important;
}
.shoppingCard {
  border-radius: 15px !important;
}

.cartTotalHeader {
  font-size: 16px !important;
  color: grey !important;
  font-weight: 500 !important;
  // padding-top: 10px !important;
}

.cartTotalContent {
  text-align: right !important;
  font-size: 12px !important;
  vertical-align: "middle";
  display: "inline-flex";
  padding-right: 20px;
}
.cartTotalSubContent {
  font-size: 13px !important;
}
.offerButton {
  border-radius: 50px !important;
  color: white !important;
  // color:black !important;
  // padding-left: 30px !important;
  // padding-right: 30px !important;
}
.estimateCard {
  padding: 30px 10px 10px 10px;
  border-radius: 15px !important;
  border: 0;
  max-height: 655px;
  //  box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
}

.loading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(6) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.alert { animation: fadeout 10s;
}

@keyframes fadeIn {
  100% { opacity: 1; }
  20% { opacity: 0; }
}

@keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}