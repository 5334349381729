.taskListWrapper {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
  border-right:#b3b0b0 1px solid;
  table-layout: auto;
  /* width: 500px; */
}

 .taskListTableRow { 
  display: table-row;
  /* text-overflow:clip; */
  border: #b3b0b0;
  overflow-x: scroll;
  
} 

.taskListTableRow:hover {
  background-color: #f9c88d!important;

}


.taskListTableRow:nth-of-type(even) {
  background-color: #f5f5f5;
  /* cursor:pointer */

}
.taskListTableRow:nth-of-type(odd) {
  background-color: #ffffff;

}
.taskListCell {
  display: table-cell;
  vertical-align: middle;
 /* white-space:wrap; */
 overflow-x: hidden;
  overflow-y: hidden;
  text-overflow:clip;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;

 
}
.taskListNameWrapper {
  display: flex;
  margin-right: 30px;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;

}

.taskListExpander {
  color: rgb(189, 183, 183);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  cursor: pointer;
}
.taskListEmptyExpander {
  font-size: 0.6rem;
  padding-left: 1rem;
  user-select: none;
}
