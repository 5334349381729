.divider{
    margin-top: 13px;
}

.subHeader {
    font-size: 15px !important;
    font-weight: 500 !important;
  }

.invoiceHeader
{
    background-color: #2E263D0A !important;
    padding: 24px !important;
    border-radius: 5px !important;
}
.companyName
{
    font-size: 20px !important;
    font-weight: 500 !important;
}
.invoiceNumber
{
    font-size: 18px !important;
    font-weight: 500 !important;
}
.logo {
    max-width: 150px;
    max-height: 100px;
  }