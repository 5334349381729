.unPaidHead{
    font-size: 22px !important;
}
/* MyCardStyles.css */
.orderHead{
    font-size: 15px;
    font-weight: 500;
    background-color: #e9e8e8;
    border-radius: 16px;
    padding: 5px;
    border: 1px solid #e5e3e3;
}
.startDateForProject{
    color: #707070;
    font-size:14px;
}
  .payNow{
    border-radius: 15px !important;
    background-color: #4979e3 !important;
  }
  .orderNo{
    color: #4979e3 !important;
  }
  .dueDate{
    justify-content: center;
    // display: grid !important;
  }
  .customer{
   color:#707070;
   font-size: 13px;
  }
  .invoicePrice{
 font-weight:500;
 font-size: 16px;
  }
  .cost{
    color:#9e0a2f;
    font-weight: 500;
  }
  .unPaidStatus{
    color:#e98c00;
    font-weight: 400 !important;
    font-size:17px !important;
  }
  .priceStatusDate{
    justify-content: center;
    display: flex;
    place-items: center;
  }
  .dueDateType{
    color:black;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  .issueDate{
    color:black !important;
  }
  .noInvoice{
    height:50vh;
  }
  .noInvoiceFound{
    justify-content: center;
    display: flex;
    top: 50%;
    position: relative;
    font-size: larger;
  }