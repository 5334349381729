.amount{
    float:right;
}
.amountHead{
    font-size: 18px;
    font-weight: 500;
} 
.amountDetails{
    font-size: 26px;
    font-weight: 600;
}
.customerSubHeader{
    font-size: 18px;
    font-weight: 500;
}
.tags{
    float:right;
    color: rgb(2, 89, 189);
}
.attachments{
    padding-top: 10px;
}
 .poTableDetails{
    font-size: 13px !important;
    border: 1px solid #ccc;
    padding: 6px !important;
    border-top:none !important;
}
.poLogo{
  
    align-items: center;
    justify-content: center;
    place-items: center;
    display:flex
}
.poHeader{
    font-size:22px !important;
}
.table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .table-container table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  
  .table-container th, .table-container td {
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .billingAddress{
    display: block !important;
    justify-content: end;
    place-items: end;
    align-items: end;
  }
  .billingAddressContainer{
    float: right;
    text-align: left;
    display: block;
    margin-left: auto !important;
  }