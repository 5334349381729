.reportBug{
    font-size: 22px !important;
    font-weight:400;
    color:rgb(255, 106, 0);
}
.reportDescription{
    color:rgb(83, 83, 83);
}
.bugIcon{
    font-size: 40px !important;
    color:rgb(255, 106, 0) !important;

}