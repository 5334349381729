.projectWrapper {
  cursor: pointer;
  outline: none;
}


.projectTop {
  user-select: none;
}

/* .projectBackground {
  user-select: none;
  opacity: 0.6;
} */