.VendorTitle {
    font-weight: 600;
    font-size: 18px;
}

.VendorHead {
    font-size: 26px;
    text-align: center;
}

.vendorcard {
    border: 0;
    box-shadow: rgba(47, 43, 61, 0.2) 0px 4px 18px 0px !important;
}

.subtitle {
    font-weight: 600;
}

a {
    position: relative;
    text-decoration: none;
    font-size: 20px;
}

#file {
    position: absolute;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    cursor: pointer;
    right: 0;
}