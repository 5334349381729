.orderNoDetails{
    padding:8px !important;
}
.headLeftDetails{
    font-size:14px;
    
}
.statusAwaitingApproval{
    background-color: #f1dfda !important;
    color: #ff6f43 !important;
    justify-content: center;
    display: flex;
    text-align: center;
  }
  .notaskDisplay{
    justify-content: center;
    display: flex;
    text-align: center;
    place-items: center;
    min-height: 400px;
    font-size:24px !important
  }
  .salesApproval{
    background-color: #c6ccee !important;
    color: #1e35bb !important;
    justify-content: center;
    display: flex;
    text-align: center;
    border-radius: 7px;
    font-size:12px !important;
  }
 