#responsive-dialog-title{
    font-weight: 400;
    font-size:16px !important;
    padding: 10px 20px;
}
.cardContent{
    box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  background-color: #ffffff !important;
  // display: flex;
  margin-bottom: 5px;
  border: 1px solid #e7e7e7;

  padding: 0.5rem;
  // position: relative;
  margin-top: 12px !important;
}
.issueDescription{
    font-size: 14px !important;
}
.issueContent{
   padding-top:15px !important;
}