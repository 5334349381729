.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    min-height: 113px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.primaryTonalButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #3c5cfa36 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
   margin-top: 7px !important;
  color: #3c5bfa !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
 
  &:hover {
    background-color: #3c5cfa31 !important;
    box-shadow: none !important;
  }
 
  &:active {
    box-shadow: none !important;
  }
 
  &:focus {
    box-shadow: none !important;
  }
}


.ErrorTonalButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #f58cbd36 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
   margin-top: 7px !important;
   
  color: #fa3c45 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
 
  &:hover {
    background-color: #fa3c5c31 !important;
    box-shadow: none !important;
  }
 
  &:active {
    box-shadow: none !important;
  }
 
  &:focus {
    box-shadow: none !important;
  }
}



.css-16g5rpk-MuiGrid-root {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-left: 10px;
}


.table {
  min-width: 650px;
  background-color: #c2cbf8 !important;
}

.table-header {
  background-color: blue;
}

.table-row {
  background-color: lightgreen;
}

.table-cell {
  color: white;
}


.prmaryiconButton {
  color: rgb(185, 185, 190); /* Default color */
}

.prmaryiconButton:hover {
  color: #3c5bfa !important; /* Hover color */
}


.erroriconButton {
  color: rgb(185, 185, 190); /* Default color */
}

.erroriconButton:hover {
  color: red; /* Hover color */
}
.configImg {
  max-height: 159px;
  max-width: 100%;
}
.logoText{
  font-size:13px !important;
}