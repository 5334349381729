.invoiceRows {
    font-size: 14px !important;
}


.errorOutlineIcon {
    color: rgb(230, 170, 7);

}

.tabs {
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    /* font-weight: 500 !important; */
}


.tablerows {
    border: 1px solid #ccc;
    padding: 8px !important;
    border-top:none !important;
    font-size: 14px !important;
    /* border-left:none !important; */
}

.invoiceRows {
    border: 1px dashed #ccc !important;
    padding: 15px !important;
    font-size: 14px !important;
}
