.ganttTable {
  display:table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
  width: 300px;
  position: sticky;
  top: 0;
  background-color: rgb(240, 240, 240);
  z-index: 1;
  /* overflow-x: scroll; */
}

.ganttTable_Header {
  display: table-row;
  list-style: none;
  border:1px solid #8f8787 !important;
  /* background-color: #6fbff4 */

}

.ganttTable_HeaderSeparator {
  border-right: 1px solid rgb(97, 71, 71);
  opacity: 1;
  margin-left: -2px;
  border:1px solid #8f8787 !important;
}

.ganttTable_HeaderItem {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  /* border:1px solid #8f8787 !important; */
}
