.warningIcon{
    background-color:#f1c820 !important;
    border-radius:none !important;
    place-items: center;
    display: flex;
    justify-content: center;
}
.bookingWarning{
    font-size:38px !important;
    color:white !important;

}
.warningDialog{
    border-radius: 13px !important;

}
.warningMessage{
    font-size:22px !important;
    place-items: center;
    display: flex;
    justify-content: center;
}
.warningDate{
    color:black;
    font-weight: 500;
}
.warningIcon .bookingWarning {
    border-radius: 15px !important;
  }
  