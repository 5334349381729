.float-chatbot-container {
    position: fixed;
    bottom: 0;
    right: 256px;
    transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */
    transform: translateY(100%);
  }
  
  .float-chatbot-container.open {
    transform: translateY(0);
  }
  