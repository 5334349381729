.otpIcon{
    height:120px;
    max-width: fit-content;
}
.otpGeneraterHead{
    display: flex;
    place-items: center;
    justify-content: center;
}
.otpHead{
    font-size :15px !important;
    font-weight: 600 !important;
    text-align: center;
}
.otpNumber{
    font-size :22px !important;
    font-weight: 500;
    color: rgba(194, 5, 5, 0.986) ;


}
.otporderNo{
    color: rgba(5, 40, 194, 0.986) ;
    font-size :16px !important;
}
.passwordIcon{
    font-size: 35px !important;
}
.projectConfirm{
    font-size:19px !important;
    font-weight: 500;
}
.jobNo{
    color:rgb(9, 62, 236) !important;
    font-size: 16px !important;
}
.confirmNames{
    color:rgb(80, 78, 78) !important;
}